



.main2{
    display: inline-block;
    position: relative;
    align-items: center;
    justify-content: center;
   margin-top: 200px;
   margin-left: 350px;
   
}

.wrapper{
    display: inline-flex;
    width: 800px;
    height: 300px;
}


.wrapper .static-txt{
color: white;
font-size: 60px;
font-weight: 400;
}

.wrapper .dynamic-txts{
 line-height: 100px;
 height: 90px;
 overflow: hidden; 
}

.dynamic-txts li{
    color: rgb(239, 49, 65);
    font-size: 60px;
    font-weight: 500;
    list-style: none;
    top: 0;
    position: relative;
    animation: slide 15s steps(5) infinite;
}


@keyframes slide {
    100%{
        top:-555px;
    }
}

.dynamic-txts li span{
    position: relative;

}

.dynamic-txts li span::after{
 content: "";
position: absolute;
height: 85px;
width: 340px;
border-left: 2px solid rgb(239, 49, 65);
background:black ;
left: 0;
animation: typing 3.0s steps(12) infinite;
}

@keyframes typing {
    100%{
        left: 100%;
        margin: 0 -35px 0 35px;
    }
}

@media  (max-width:769px){
    .main2{
        
         display: inline-block;
        
         margin-left: 430px;
    }
}