
.main4{
    width: 1480px;
    position: relative;
    bottom: 0;
    background: linear-gradient(to right, #421a01, #2d0b00);
    color: #fff;
    padding: 100px 0 30px;
    border-top-left-radius: 125px;
    font-size: 13px;
    line-height: 20px; 
    margin-top: 900px; 
}

.row2{
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
     align-items: flex-start;
     justify-content: space-between;
     

}

.col{
    flex-basis: 25%;
    padding: 10px;
}
.col:nth-child(2),.col:nth-child(3) {
    flex-basis: 15%;
}

.footerlogo{
    width: 170px;
    margin-bottom: 40px;
    position: relative;
}

.col h3{
    width: fit-content;
    margin-bottom: 40px;
    position: relative;
}

.email-id{
    width: fit-content;
    border-bottom: 1px solid #ccc;
    margin: 20px 0;
}
ul li{
    list-style: none;
    margin-bottom: 12px;
}
ul li a{
    text-decoration: none;
    color: #fff;
}
.social-icons .fab{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: #000;
    background: #fff;
    margin-right: 15px;
    
}
hr{
    width: 90%;
    border: 0;
    border-bottom: 1px solid #ccc;
    margin: 20px auto;
}
.copyright{
    text-align: center;

}

.underline{
    width: 100%;
    height: 5px;
    background: #767676;
    border-radius: 3px;
    position: absolute;
    top: 25px;
    left: 0;
}
.underline span{
    width: 15px;
    height: 100%;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 10px;
    animation: moving 2s linear infinite;
}
@keyframes moving{
    0%{
        left: -20px;
    }
    100%{
        left: 100%;
    }
}

@media (max-width:700px){
    .main4{
       margin-top: 2200px;
    }
}
