.row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: wrap;
  margin-left: 160px;
  gap: 60px;
 
}



.box{
    position:relative;
    width: 300px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #060c21;
    margin-bottom: 50px;
    margin-left: 10px;
    transition: 0.3s;
    margin-top: 200px;

}



.box:before{
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: white;
    z-index: -1;
}

.box::after{
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: white;
    z-index: -2;
    filter: blur(40px);
}


.box:nth-child(1):before,
.box:nth-child(1):after
{
    background: linear-gradient(235deg,#89ff00,#010615,#00bcd4);
}

.box:nth-child(2):before,
.box:nth-child(2):after
{
    background: linear-gradient(235deg,#ff005e,#010615,#fbff00);
}



.box:nth-child(3):before,
.box:nth-child(3):after
{
    background: linear-gradient(235deg,#772aff,#010615,#2196F3);
}



.box .glass{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    display: block;
    background: rgba(255,255,255,.1);
    pointer-events: none;
}
.box .contant{
    padding: 20px;
    color: white;

}
.card-body{
    font-size: 18px;
    text-align: center;
    padding: 30px 20px;
}
.card-header {
    text-align: center;
    padding:  7px;
    color: #fff;

}
@media  (max-width:1000px){
    .row{
      margin-left: 450px;
      width: 600px;
      height: 700px;
       
    }
}

@media  (max-width:900px){
    .glass{
        
      width: 60%;
      
       
    }
    .contant{
        padding: 20px;
        font-size: 80%;
        display: inline-block;
        margin-left: 15px;
        align-items: center;
        justify-content: center;
    }
    .box{
        width: 70%;
    }

}