

*{
    box-sizing: border-box;
    font-family: 'poppins', sans-serif;
    margin: 0;
    padding: 0;
 
}

body{
    min-height: 100vh;
    background: linear-gradient(#121212,#0a0a0a,#0c0c0c);
    margin: 0;
    padding: 0;

}
.main{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.logo{
    color: white;
    font-weight: 700;
    text-decoration: none;
    font-size: 2em;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 6px;
    margin-top: 10px;
  
  
    
}
.navbar{
   margin-left: 200px;
}

#navbarj{
    display: flex;
    justify-content: center;
    align-items: center;

}

.navbarjk{
    list-style: none;
    margin-left: 40px;
    gap: 3px;
}

#a{
    text-decoration: none;
    padding: 8px 35px;
    color: white;
    border-radius: 30px;
}

#a:hover ,
#a.active 
{
    background:  #ecb02b;
    color:  #5c0c06;
}

#Ant_Fire{
  gap: 2rem;
  flex-wrap: wrap-reverse;
  align-items: center;
  min-height: calc(100vh - 93.6px); 
}
#Ant_Fire>*{
    flex: 1 1 25em;
}
.intention{
    position:absolute;
    margin-top: 100px;
    width: 400px;
    margin-left: 100px;
  
   
}
#mobile{
    display: none ;
    margin-left: 1300px;
    position: absolute;
}

#mobile i{
    color: #fff;
   align-items: center;
}






.vision{
    position: absolute;
    display: flex;
    align-items: center;
    top: 40%;
    left: 35%;
    transform: translate(-50%,-50%);
    font-size: 45px;
    /* -webkit-text-stroke: 1px  #ecb02b ; */
    color: transparent;
    background-image: linear-gradient(white,#ecb02b);
    background-repeat: no-repeat;
   -webkit-background-clip: text;
    background-position: -800px,0;
    animation: backcolor 6s linear infinite alternate;
}
@keyframes backcolor {
    100%{
        background-position: 0 0;
    }
}

@media screen and (max-width:769px) {
    
    #navbarj{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed;
        top: 10px;
        right: -450px;
        width: 400px;
        height: 110vh;
        background-color: #252525;
       box-shadow: rgba(248, 238, 111, 0.547) 0px 30px 90px;
        padding: 80px 0 0 10px;
        list-style: none;
        margin-left: 40px;
        gap: 40px;
        transition: 0.3s ease-in-out;
    
    }

    #navbarj.active{
        right: 0px;
    }
    #mobile{
        display: block;
      
    }
    #mobile i{
        font-size: 24px;
        cursor: pointer;
    }
    
        
    }
